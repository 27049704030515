import { PROJECT_TYPE } from "shared/utils/constants";

export const POSTS_PER_PAGE = 12;
export const SEARCH_RESULTS_PER_PAGE = 20;
export const RELATED_POSTS_AMOUNT = 3;

export const BLOG_TYPE = "ineo.blog";
export const COOKIES_TYPE = "ineo.cookiesPage";
export const EVENT_TYPE = "ineo.event";
export const FOOTER_TYPE = "ineo.footer";
export const HOMEPAGE_TYPE = "ineo.homepage";
export const HUB_OVERVIEW_TYPE = "ineo.hubOverview";
export const LANDING_TYPE = "ineo.landing";
export const NAVIGATION_TYPE = "ineo.navigation";
export const NEWS_TYPE = "ineo.news";
export const PAGE_NOT_FOUND_TYPE = "ineo.pageNotFound";
export const PAGE_TYPE = "ineo.page";
export const UPDATE_TYPE = "update";
export const RESOURCE_TYPE = "ineo.resource";

export const NEWS_EVENTS_OVERVIEW_TYPE = "ineo.newsEventsOverview";
export const PROJECT_OVERVIEW_TYPE = "ineo.projectOverview";
export const PERSON_OVERVIEW_TYPE = "ineo.personOverview";
export const RESOURCE_FINDER_TYPE = "ineo.resourceFinder";
export const RESOURCETYPE_OVERVIEW_TYPE = "ineo.resourceTypeOverview";
export const DEFINITION_OVERVIEW_TYPE = "ineo.definitionOverview";
export const RESEARCH_ACTIVITY_OVERVIEW_TYPE = "ineo.researchActivityOverview";
export const RESEARCH_DOMAIN_OVERVIEW_TYPE = "ineo.researchDomainOverview";
export const INFORMATION_TYPE_OVERVIEW_TYPE = "ineo.informationTypeOverview";

export const RESOURCE_RESEARCH_DOMAIN_TYPE = "resource.researchDomain";
export const RESOURCE_RESEARCH_ACTIVITY_TYPE = "resource.researchActivity";
export const RESOURCE_INFORMATION_TYPE = "resource.informationType";

export const INEO_RELATED_ITEMS = [
  { type: BLOG_TYPE },
  { type: EVENT_TYPE },
  { type: LANDING_TYPE },
  { type: NEWS_TYPE },
  { type: PAGE_TYPE },
  { type: PROJECT_TYPE },
];

export const INEO_ALLOWED_NAVIGATION_TYPES = [
  BLOG_TYPE,
  COOKIES_TYPE,
  EVENT_TYPE,
  HUB_OVERVIEW_TYPE,
  LANDING_TYPE,
  NEWS_EVENTS_OVERVIEW_TYPE,
  NEWS_TYPE,
  PAGE_TYPE,
  PROJECT_OVERVIEW_TYPE,
  PROJECT_TYPE,
  RESOURCE_FINDER_TYPE,
  HOMEPAGE_TYPE,
  RESOURCETYPE_OVERVIEW_TYPE,
  DEFINITION_OVERVIEW_TYPE,
  RESEARCH_ACTIVITY_OVERVIEW_TYPE,
  RESEARCH_DOMAIN_OVERVIEW_TYPE,
  INFORMATION_TYPE_OVERVIEW_TYPE,
];

export const INEO_INTERNAL_LINK_TYPES = [
  { type: LANDING_TYPE },
  { type: NEWS_EVENTS_OVERVIEW_TYPE },
  { type: PAGE_TYPE },
  { type: PROJECT_OVERVIEW_TYPE },
  { type: RESOURCE_FINDER_TYPE },
  { type: RESOURCETYPE_OVERVIEW_TYPE },
  { type: HOMEPAGE_TYPE },
  { type: COOKIES_TYPE },
  { type: DEFINITION_OVERVIEW_TYPE },
];
