import {
  BLOG_TYPE,
  COOKIES_TYPE,
  EVENT_TYPE,
  NEWS_EVENTS_OVERVIEW_TYPE,
  NEWS_TYPE,
  PAGE_TYPE,
  PERSON_OVERVIEW_TYPE,
  PROJECT_OVERVIEW_TYPE,
  UPDATE_TYPE,
  PERSON_TYPE,
} from "./constants";

import { PROJECT_TYPE, SEARCH_TYPE } from "shared/utils/constants";

export const types = {
  [BLOG_TYPE]: { nl: "blog/", en: "blog/" },
  [COOKIES_TYPE]: { nl: "cookies/", en: "cookies/" },
  [EVENT_TYPE]: { nl: "evenementen/", en: "events/" },
  [NEWS_TYPE]: { nl: "nieuws/", en: "news/" },
  [PAGE_TYPE]: { nl: "", en: "" },
  [PERSON_TYPE]: { nl: "personen/", en: "people/" },
  [PERSON_OVERVIEW_TYPE]: { nl: "personen/", en: "people/" },
  [PROJECT_TYPE]: { nl: "projecten/", en: "projects/" },
  [PROJECT_OVERVIEW_TYPE]: { nl: "projecten/", en: "projects/" },
  [SEARCH_TYPE]: { nl: "zoeken", en: "search" },
  [UPDATE_TYPE]: { nl: "nieuws-evenementen/", en: "news-events/" },
  [NEWS_EVENTS_OVERVIEW_TYPE]: {
    nl: "nieuws-evenementen/",
    en: "news-events/",
  },
};
