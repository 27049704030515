import { PROJECT_TYPE } from "shared/utils/constants";

export const LAPOSTA_NEWSLETTER_FORM_URL =
  "https://clariah.email-provider.nl/memberforms/subscribe/standalone/form/?a=4olzg7aznf&l=vsxq4akqhr";

export const POSTS_PER_PAGE = 12;
export const SEARCH_RESULTS_PER_PAGE = 20;
export const RELATED_POSTS_AMOUNT = 3;

export const BLOG_TYPE = "clariah.blog";
export const COOKIES_TYPE = "clariah.cookiesPage";
export const EVENT_TYPE = "clariah.event";
export const FOOTER_TYPE = "clariah.footer";
export const HOMEPAGE_TYPE = "clariah.homepage";
export const HUB_OVERVIEW_TYPE = "clariah.hubOverview";
export const LANDING_TYPE = "clariah.landing";
export const NAVIGATION_TYPE = "clariah.navigation";
export const NEWS_TYPE = "clariah.news";
export const PAGE_NOT_FOUND_TYPE = "clariah.pageNotFound";
export const PAGE_TYPE = "clariah.page";
export const PERSON_TYPE = "clariah.person";
export const REDIRECT_TYPE = "clariah.redirect";
export const UPDATE_TYPE = "update";

export const NEWS_EVENTS_OVERVIEW_TYPE = "clariah.newsEventsOverview";
export const PROJECT_OVERVIEW_TYPE = "clariah.projectOverview";
export const PERSON_OVERVIEW_TYPE = "clariah.personOverview";

export const DEPARTMENT_TYPE = "clariah.department";

export const CLARIAH_RELATED_ITEMS = [
  { type: BLOG_TYPE },
  { type: EVENT_TYPE },
  { type: LANDING_TYPE },
  { type: NEWS_TYPE },
  { type: PAGE_TYPE },
  { type: PROJECT_TYPE },
];

export const CLARIAH_ALLOWED_NAVIGATION_TYPES = [
  BLOG_TYPE,
  COOKIES_TYPE,
  EVENT_TYPE,
  HUB_OVERVIEW_TYPE,
  LANDING_TYPE,
  NEWS_EVENTS_OVERVIEW_TYPE,
  NEWS_TYPE,
  PAGE_TYPE,
  PERSON_OVERVIEW_TYPE,
  PERSON_TYPE,
  PROJECT_OVERVIEW_TYPE,
  PROJECT_TYPE,
];

export const CLARIAH_INTERNAL_LINK_TYPES = [
  { type: LANDING_TYPE },
  { type: NEWS_EVENTS_OVERVIEW_TYPE },
  { type: PAGE_TYPE },
  { type: PERSON_OVERVIEW_TYPE },
  { type: PROJECT_OVERVIEW_TYPE },
];
