import { types } from "./types";
import { types as clariahTypes } from "../../clariah/utils/types";

type Props = {
  href?: string;
  type?: string;
  isOutOfScope?: boolean;
};

export const createInternalHref = ({
  href = "",
  type,
  isOutOfScope,
}: Props) => {
  let typePart = types[type] || "";
  if (isOutOfScope) {
    typePart = clariahTypes[type]?.en || "";
    return `${process.env.NEXT_PUBLIC_CLARIAH_DOMAIN}/${typePart}${href}`.replace(
      /\/$/g,
      ""
    );
  }
  return `/${typePart}${href}`.replace(/\/$/g, "");
};
