import {
  BLOG_TYPE,
  COOKIES_TYPE,
  EVENT_TYPE,
  NEWS_EVENTS_OVERVIEW_TYPE,
  NEWS_TYPE,
  PAGE_TYPE,
  PROJECT_OVERVIEW_TYPE,
  RESOURCE_TYPE,
  UPDATE_TYPE,
  RESOURCE_FINDER_TYPE,
  RESOURCETYPE_OVERVIEW_TYPE,
  RESOURCE_RESEARCH_DOMAIN_TYPE,
  RESOURCE_RESEARCH_ACTIVITY_TYPE,
  RESOURCE_INFORMATION_TYPE,
  DEFINITION_OVERVIEW_TYPE,
  RESEARCH_ACTIVITY_OVERVIEW_TYPE,
  RESEARCH_DOMAIN_OVERVIEW_TYPE,
  INFORMATION_TYPE_OVERVIEW_TYPE,
} from "./constants";

import { PROJECT_TYPE } from "shared/utils/constants";

export const DEFINITION_TYPES = {
  [RESOURCE_RESEARCH_ACTIVITY_TYPE]: {
    title: "Research Activities",
    key: "researchActivities",
    facet: "facetResearchActivities",
    slug: "research-activities",
  },
  [RESOURCE_RESEARCH_DOMAIN_TYPE]: {
    title: "Research Domains",
    key: "researchDomains",
    facet: "facetResearchDomains",
    slug: "research-domains",
  },
  [RESOURCE_INFORMATION_TYPE]: {
    title: "Information Types",
    key: "informationTypes",
    facet: "facetInformationTypes",
    slug: "information-types",
  },
};

export const types = {
  [BLOG_TYPE]: "blog/",
  [COOKIES_TYPE]: "cookies/",
  [EVENT_TYPE]: "events/",
  [NEWS_TYPE]: "news/",
  [PAGE_TYPE]: "",
  [PROJECT_TYPE]: "projects/",
  [RESOURCE_TYPE]: "resources/",
  [PROJECT_OVERVIEW_TYPE]: "projects/",
  [UPDATE_TYPE]: "news-events/",
  [NEWS_EVENTS_OVERVIEW_TYPE]: "news-events/",
  [RESOURCE_FINDER_TYPE]: "resources/",
  [RESOURCETYPE_OVERVIEW_TYPE]: "resource-types/",
  [DEFINITION_OVERVIEW_TYPE]: "definitions/",
  [RESEARCH_ACTIVITY_OVERVIEW_TYPE]: "definitions/research-activities/",
  [RESEARCH_DOMAIN_OVERVIEW_TYPE]: "definitions/research-domains/",
  [INFORMATION_TYPE_OVERVIEW_TYPE]: "definitions/information-types/",
  [RESOURCE_RESEARCH_ACTIVITY_TYPE]: "definitions/research-activities/",
  [RESOURCE_RESEARCH_DOMAIN_TYPE]: "definitions/research-domains/",
  [RESOURCE_INFORMATION_TYPE]: "definitions/information-types/",
};
